import React from "react";
import "./promo.scss";
import penawaran from "../../Media/Hardsell_Kotwis.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble, faMedal} from "@fortawesome/free-solid-svg-icons";

const Penawaran = () => {
  const handleConsultationClick = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281818160028&text=Halo+Christine,%20Saya%C2%A0bisa%20minta%20detail%20Promo%20terbaru%20rumah%20Kota%20Wisata%20Cibubur%20(Promo)+https://marketing-kotawisata.id/&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  const settings1 = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    lazyload: true,
  };
  return (
    <div className="judulpromo">
      <div id="promo" className=" containerPenawaran">
        <div className="containergambar">
          <img className="penawarangambar" src={penawaran} />
        </div>
        <div className="containercontent">
          <div className="judul">
            <h1>PROMO KOTA WISATA CIBUBUR</h1>
          </div>
          <div className="pointpenawaran">
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Siap Huni</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Free DP</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Discount up to 13%
              </span>
            </div>

            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Subsidi Biaya KPR
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Free Solar Panel</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Free Smarthome System
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Free Smartdoor Lock
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Free CCTV</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Full Lift</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Voucher Travel</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faMedal} />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Instan Approval KPR
              </span>
            </div>
          </div>
          <div className="disclaimer">*Disclaimer</div>
          <button className="buttonpenawaran" onClick={handleConsultationClick}>
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default Penawaran;
