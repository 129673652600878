import React from "react";
import "./Produk2.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faLocationDot,
  faMedal,
} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import Athertonimages from "./Atherton";
import Richmondimages from "./Richmond";
import Alteaimages from "./Altea";

const Produk2 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const Gardenwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281818160028&text=Halo%20Christine,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Kota%20Wisata%20Cibubur%20(Altea)%20https://marketing-kotawisata.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Cherrywa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281818160028&text=Halo%20Christine,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Kota%20Wisata%20Cibubur%20(Atherton)%20https://marketing-kotawisata.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const WTwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281818160028&text=Halo%20Christine,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Kota%20Wisata%20Cibubur%20(Richmond)%20https://marketing-kotawisata.id//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="container">
      <div className="container-card3">
        <div className="card">
          <Slider {...settings}>
            {Alteaimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Altea</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="#ad7e36"
                icon={faMedal}
              />
              <div className="namalokasi">Soft DP</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#091c34" icon={faArrowsUpDownLeftRight} />
            <span> 170m²</span>
            <FontAwesomeIcon color="#091c34" icon={faHouse} />
            <span> 192m²</span>
            <FontAwesomeIcon color="#091c34" icon={faBed} />
            <span> 4</span>
            <FontAwesomeIcon color="#091c34" icon={faShower} />
            <span> 4</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">14 Juta/</div>
              <div className="ket">Bulan</div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Gardenwa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Athertonimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Atherton</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="#ad7e36"
                icon={faMedal}
              />
              <div className="namalokasi">Siap Huni, Free DP</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#091c34" icon={faArrowsUpDownLeftRight} />
            <span> 180m²</span>
            <FontAwesomeIcon color="#091c34" icon={faHouse} />
            <span> 237m²</span>
            <FontAwesomeIcon color="#091c34" icon={faBed} />
            <span> 4+1</span>
            <FontAwesomeIcon color="#091c34" icon={faShower} />
            <span> 4+1</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">33 Juta/</div>
              <div className="ket">Bulan</div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Cherrywa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Richmondimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Richmond</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="#ad7e36"
                icon={faMedal}
              />
              <div className="namalokasi">Subsidi DP</div>
            </div>
          </div>

          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#091c34" icon={faArrowsUpDownLeftRight} />
            <span> 200m²</span>
            <FontAwesomeIcon color="#091c34" icon={faHouse} />
            <span> 332m²</span>
            <FontAwesomeIcon color="#091c34" icon={faBed} />
            <span> 4</span>
            <FontAwesomeIcon color="#091c34" icon={faShower} />
            <span> 4</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">37 Juta/</div>
              <div className="ket">Bulan</div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={WTwa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk2;
