import React from "react";
import "./home.scss";
import Navbar from "../Section/Header/navbar";
import Banner from "../Section/Banner/banner";
import About from "../Section/About/about";
import Promo from "../Section/Promo/promo";
import Rumah from "../Section/Rumah/rumah";
import Ruko from "../Section/Ruko/ruko.jsx";
import Surrounding from "../Section/Surrounding Area/surrounding.jsx";
import Lokasi from "../Section/Lokasi/lokasi.jsx";
import Footer from "../Section/Footer/footer.jsx";
import tombol from "../Media/tombol.webp";
import ScrollToHashElement from "../Pages/ScrollToHashElement.js";

const home = () => {
  const fungsiganteng = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281818160028&text=Halo%20Christine,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20Kota%20Wisata%20https://marketing-kotawisata.id//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  return (
    <div className="home">
      <ScrollToHashElement />
      <Navbar />
      <Banner />
      <About />
      <Promo />
      <Rumah />
      <Ruko />
      <Surrounding />
      <Lokasi />
      <Footer />
      <img
        onClick={fungsiganteng}
        src={tombol}
        className="tombolwa"
        alt="Hubungi Sekarang!"
      />
    </div>
  );
};

export default home;
