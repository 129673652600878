import React from "react";
import "./Produk1.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Davinciimages from "./Davinci";
import Missisipiimages from "./Missisipi";
import Vermontimages from "./Vermont";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faLocationDot,
  faMedal,
} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const Produk1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const O8wa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281818160028&text=Halo%20Christine,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Kota%20Wisata%20Cibubur%20(DaVinci)%20https://marketing-kotawisata.id//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  const Tanamaswa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281818160028&text=Halo%20Christine,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Kota%20Wisata%20Cibubur%20(Vermont)%20https://marketing-kotawisata.id//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  const Zlivwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281818160028&text=Halo%20Christine,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Kota%20Wisata%20Cibubur%20(Missisipi)%20https://marketing-kotawisata.id//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };

  return (
    <div className="container">
      <div className="containercard2">
        <div className="card">
          <Slider {...settings}>
            {Davinciimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Da Vinci</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="#ad7e36"
                icon={faMedal}
              />
              <div className="namalokasi">Subsidi DP</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#091c34" icon={faArrowsUpDownLeftRight} />
            <span> 96m²</span>
            <FontAwesomeIcon color="#091c34" icon={faHouse} />
            <span> 70m²</span>
            <FontAwesomeIcon color="#091c34" icon={faBed} />
            <span> 2</span>
            <FontAwesomeIcon color="#091c34" icon={faShower} />
            <span> 2</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">8 Juta/</div>
              <div className="ket">Bulan</div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={O8wa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Vermontimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Vermont</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="#ad7e36"
                icon={faMedal}
              />
              <div className="namalokasi">Siap Huni, Free DP</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#091c34" icon={faArrowsUpDownLeftRight} />
            <span> 91m²</span>
            <FontAwesomeIcon color="#091c34" icon={faHouse} />
            <span> 110m²</span>
            <FontAwesomeIcon color="#091c34" icon={faBed} />
            <span> 3+1</span>
            <FontAwesomeIcon color="#091c34" icon={faShower} />
            <span> 3+1</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">10 Juta/</div>
              <div className="ket">Bulan</div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Tanamaswa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Missisipiimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Missisipi</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="#ad7e36"
                icon={faMedal}
              />
              <div className="namalokasi">Siap Huni, Free DP</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#091c34" icon={faArrowsUpDownLeftRight} />
            <span> 100m²</span>
            <FontAwesomeIcon color="#091c34" icon={faHouse} />
            <span> 200m²</span>
            <FontAwesomeIcon color="#091c34" icon={faBed} />
            <span> 3+1</span>
            <FontAwesomeIcon color="#091c34" icon={faShower} />
            <span> 3+1</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">11 Juta/</div>
              <div className="ket">Bulan</div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Zlivwa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk1;
