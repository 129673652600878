import Altea1 from '../../../Media/Kotwis/Altea/L7 (1).webp'
import Altea2 from '../../../Media/Kotwis/Altea/L7 (2).webp'
import Altea3 from '../../../Media/Kotwis/Altea/L7 (3).webp'
import Altea4 from '../../../Media/Kotwis/Altea/L7 (4).webp'
import Altea5 from '../../../Media/Kotwis/Altea/L7 (5).webp'
import Altea6 from '../../../Media/Kotwis/Altea/L7 (6).webp'
import Altea7 from '../../../Media/Kotwis/Altea/L7 (7).webp'
import Altea8 from '../../../Media/Kotwis/Altea/L7 (8).webp'
import Altea9 from '../../../Media/Kotwis/Altea/L7 (9).webp'


const Alteaimages =[
Altea1,Altea2,Altea3,Altea4,Altea5,Altea6,Altea7,Altea8,Altea9
]

export default Alteaimages;